import React, { Component } from "react";
import { Trans } from "react-i18next";
import { Row, Col, ListGroup, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

import { ErrorMessage } from "../ErrorMessage";

import timerIcon from "../../assets/timer.svg";
import wiredIcon from "../../assets/wired.svg";
import wifiIcon from "../../assets/wireless2.svg";
//import rightArrowIcon from "../../assets/right-arrow.svg";

class NetworkManager extends Component {
  state = {
    wifiConnected: false,
    lanConnected: false,

    error: null
  };

  _isMounted = false;

  controller = null;

  netInfo = () => {
    this.controller = new AbortController();

    fetch("//rouster/network/info", { signal: this.controller.signal })
      .then(response => {
        if (!response.ok) {
          throw Error("Error while getting network data: " + response.statusText);
        }
        return response.json();
      })
      .then(json => {
        if (this._isMounted && Array.isArray(json) && json.length > 0) {
          json
            .filter(e => e.state === "up" && "ip_address" in e)
            .map(i => {
              if (i.interface.startsWith("eth")) {
                this.setState({ lanConnected: i });
              }
              if (i.interface.startsWith("wlan")) {
                this.setState({ wifiConnected: i });
              }
              return i;
            });
        }
      })
      .catch(err => {
        this.setState({ error: err });
      });
  };

  componentDidMount() {
    this._isMounted = true;

    this.netInfo();
  }

  componentWillUnmount() {
    this._isMounted = false;

    if (this.controller) {
      this.controller.abort();
    }
  }

  render() {
    if (this.state.error != null) {
      return <ErrorMessage message={this.state.error.toString()} />;
    }
    return (
      <div>
        <h4 className="m-3">
          <Trans>TITLE_NETWORK_SETTINGS</Trans>
        </h4>
        <Row className="mt-2">
          <Col>
            <ListGroup variant="flush">
              <ListGroup.Item action>
                <Nav.Link as={Link} to="/network/wired-connection">
                  <i
                    className="mr-4"
                    style={{
                      verticalAlign: "bottom",
                      width: 40,
                      height: 40,
                      backgroundImage: `url(${wiredIcon})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      display: "inline-block"
                    }}
                  />

                  <label>
                    <Trans>LBL_WIRED_CONNECTION</Trans>
                    <small style={{ display: "block", lineHeight: ".7rem" }}>
                      {this.state.lanConnected && <Trans>LBL_CONNECTED</Trans>}
                    </small>
                  </label>
                </Nav.Link>
              </ListGroup.Item>
              <ListGroup.Item action>
                <Nav.Link as={Link} to="/network/wifi-manager">
                  <i
                    className="mr-4"
                    style={{
                      verticalAlign: "bottom",
                      width: 40,
                      height: 40,
                      backgroundImage: `url(${wifiIcon})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      display: "inline-block"
                    }}
                  />

                  <label>
                    <Trans>LBL_WIRELESS_CONNECTION</Trans>
                    <small style={{ display: "block", lineHeight: ".7rem" }}>
                      {this.state.wifiConnected && <Trans>LBL_CONNECTED</Trans>}
                    </small>
                  </label>
                </Nav.Link>
              </ListGroup.Item>

              <ListGroup.Item action>
                <Nav.Link as={Link} to="/network/speed-test">
                  <i
                    className="mr-4"
                    style={{
                      verticalAlign: "bottom",
                      width: 40,
                      height: 40,
                      backgroundImage: `url(${timerIcon})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      display: "inline-block"
                    }}
                  />

                  <label>
                    <Trans>LBL_SPEED_TEST</Trans>
                    <small style={{ display: "block", lineHeight: ".7rem" }}>
                      <Trans>SUB_SPEED_TEST</Trans>
                    </small>
                  </label>
                </Nav.Link>
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      </div>
    );
  }
}

export default NetworkManager;
