import { Component } from "react";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import config from "./config.js";

import * as query from "../graphql/queries";
import * as mutation from "../graphql/mutations";

Amplify.configure(config.amplify);

class Remote extends Component {
  timerSec = 10;
  timer = null;
  statusCheckHandler = null;
  powerOnSent = false;

  _isMounted = false;

  countDown = fnc => {
    if (this.timerSec < 0) {
      clearInterval(this.timer);
      fnc();
    } else if (null === this.timer) {
      this.timer = setInterval(() => {
        this.props.reportStatus(`Starting in ${this.timerSec} sec.`);
        this.timerSec--;
        this.countDown(fnc);
      }, 1000);
    }
  };

  powerOn = () => {
    API.graphql(graphqlOperation(mutation.CloudComputerStart)).then(() => {
      this.powerOnSent = true;
    });
  };

  getInfo = () => {
    API.graphql(graphqlOperation(query.CloudComputerInfo))
      .then(response => {
        if (
          this._isMounted &&
          response["data"]["CloudComputerInfo"]["state"] &&
          response["data"]["CloudComputerInfo"]["state"] !== null
        ) {
          var state = response["data"]["CloudComputerInfo"]["state"];

          this.props.setRemoteState(state);

          if (state === "ready") {
            clearInterval(this.statusCheckHandler);
            this.props.wizard.push("wizard/connect");
          } else {
            if (state === "off" && !this.powerOnSent) {
              this.countDown(() => {
                this.powerOn();
              });
            }

            if (this.statusCheckHandler === null) {
              this.statusCheckHandler = setInterval(this.getInfo, 4000);
            }
          }

          this.props.reportStatus("Turning on...");
          this.props.reportSecondaryStatus(`Preparing computer. Current status: ${state}`);
        }
      })
      .catch(err => {
        console.log(err);
        this.props.reportStatus("An error occurs while getting computer info", "danger");
        this.props.reportSecondaryStatus("Please, contact your vendor for verification!", "danger");
      });
  };

  componentWillUnmount() {
    this._isMounted = false;

    clearInterval(this.timer);
    clearInterval(this.statusCheckHandler);
  }

  componentDidMount() {
    this._isMounted = true;

    if (!this.props.user || !("Id" in this.props.user)) {
      this.props.wizard.push("wizard/network");
    } else if (!("MachineId" in this.props.user) || !this.props.user.MachineId) {
      this.props.setUser(null);
      this.props.wizard.push("machine-not-ready");
    } else {
      this.getInfo();
    }
  }

  render = () => null;
}

export default Remote;
