import React, { Component } from "react";
import { Trans } from "react-i18next";
import { Button, InputGroup, Form } from "react-bootstrap";
import { AppContext } from "../../app-context";

class WifiConnect extends Component {
  state = {
    secret: "",
    inputClass: ""
  };

  handleChange = ({ target }) => {
    this.setState({ secret: target.value });
  };

  onConnectToWifi = ({ target }) => {
    if ("" !== this.state.secret) {
      this.props.onConnectToWifi(this.props.net, this.state.secret);
      this.setState({ secret: "", connecting: true });
    } else {
      this.setState({ inputClass: "invalid" });
    }
  };

  onDisconnectWifi = () => {
    this.props.onDisconnectWifi();
    this.setState({ secret: "", disconnecting: true });
  };

  enterPressed(event) {
    var code = event.keyCode || event.which;
    if (code === 13) {
      this.onConnectToWifi(event.target);
    }
  }

  componentWillUnmount() {
    // no-op
  }

  render() {
    if (this.props.net.connected) {
      return (
        <Form.Group>
          <Form.Label className="text-center font-weight-bold mt-2">
            <input type="button" className="btn btn-danger" value="Disconnect" onClick={this.onDisconnectWifi} />
          </Form.Label>
        </Form.Group>
      );
    }

    return (
      <Form.Group>
        <Form.Label>
          <Trans>LBL_ENTER_NETKEY</Trans>
        </Form.Label>
        <InputGroup>
          <Form.Control
            type="text"
            size="sm"
            className={this.state.inputClass}
            autoFocus
            autoComplete="off"
            value={this.state.secret}
            onChange={this.handleChange}
            onKeyPress={this.enterPressed.bind(this)}
          />
          <div className="input-group-append">
            <Button type="button" variant="dark" size="sm" onClick={() => this.onConnectToWifi()}>
              <Trans>BTN_CONNECT</Trans>
            </Button>
          </div>
        </InputGroup>
      </Form.Group>
    );
  }
}

WifiConnect.contextType = AppContext;

export default WifiConnect;
