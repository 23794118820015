export const GetUserInfo = `query GetUserInfo {
  GetUserInfo(WithComputerInfo: true) {
    computer {
      id
      privateIpAddress
      publicIpAddress
    }
    Id
    Email
    CognitoId
    MachineId
    Properties {
      key
      value
    }
  }
}
`;

export const CloudComputerInfo = `query CloudComputerInfo {
  CloudComputerInfo {
      state
  }
}`;
