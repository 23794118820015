import React, { Component } from "react";
//import { Trans } from "react-i18next";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Container /*, Card*/ } from "react-bootstrap";
import { Wizard, Steps, Step } from "react-albus";

//import { RemoteIndicator } from "./components/RemoteIndicator";
import { AppContext } from "./app-context";

//import rousterLogo from "./assets/rouster-logo.svg";

import Icon from "./components/Icon";
// import Network from "./components/1.Network";
import Authenticate from "./components/2.Auth";
import Remote from "./components/3.Remote";
import Connect from "./components/4.Connect";
import { ErrorMessage } from "./components/ErrorMessage";
import SafeMode from "./components/SafeMode";
import StateBar from "./components/StateBar";

import Main from "./components/Main";

// import antennaIcon from "./assets/color/audio.svg";
import fingerprintIcon from "./assets/color/lock.svg";
import upsIcon from "./assets/color/plug.svg";
import cloudIcon from "./assets/color/link.svg";

class App extends Component {
  state = {
    user: null,
    netType: null,
    hasNet: false,
    remoteState: null,

    showMain: false,
    showSafeMode: false,
    currentStep: "auth",
    connected: false,
    clientPid: 0,

    status: "",
    statusSeverity: null,
    secondaryStatus: "",
    secondaryStatusSeverity: null
  };

  waitHandler = null;
  wizard = null;

  connectedInterval = null;

  onKbdKeyPress = button => {
    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}" || button === "{lock}") this.handleShift();
  };

  handleShift = () => {
    let layoutName = this.state.layoutName;

    this.setState({
      layoutName: layoutName === "default" ? "shift" : "default"
    });
  };

  reportStatus = (status, statusSeverity) => {
    this.setState({ status, statusSeverity });
  };

  reportSecondaryStatus = (secondaryStatus, secondaryStatusSeverity) => {
    this.setState({ secondaryStatus, secondaryStatusSeverity });
  };

  ctrlKey = false;
  altKey = false;

  keyDownHandler = event => {
    let code = event.keyCode || event.which;
    this.ctrlKey = code === 17;
    this.altKey = code === 18;

    if (code === 27 && !this.state.connected) {
      this.showMain();
    }

    if ((event.ctrlKey || this.ctrlKey) && (event.altKey || this.altKey) && event.key === "Q") {
      this.setConnected(false, 0);
    }
  };

  keyUpHandler = event => {
    let code = event.keyCode || event.which;
    this.ctrlKey = code === 17;
    this.altKey = code === 18;
  };

  componentDidMount() {
    document.addEventListener("keydown", this.keyDownHandler, false);
    document.addEventListener("keyup", this.keyUpHandler, false);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyDownHandler, false);
    document.removeEventListener("keyup", this.keyUpHandler, false);
  }

  showMain = () => {
    this.setState({ status: "", secondaryStatus: "", showMain: true });
  };

  showSafeMode = () => {
    this.setState({ status: "", secondaryStatus: "", showSafeMode: true });
  };

  hideSafeMode = () => {
    if (null !== this.wizard) {
      this.wizard.push("wizard/auth");
    }
    this.setState({ showSafeMode: false });
  };

  hideMain = () => {
    if (null !== this.wizard) {
      this.wizard.push("wizard/auth");
    }
    this.setState({ showMain: false });
  };

  storeProperties = () => {
    if (this.state.user.Properties) {
      var formData = new FormData();
      formData.append("data", JSON.stringify(this.state.user.Properties));

      // fetch("//rouster/settings/config", {
      //   method: "POST",
      //   body: formData
      // });
    }
  };

  setUser = user => {
    this.setState({ user: user });
    if (user) {
      this.storeProperties();
    }
  };

  setNetworkType = type => {
    this.setState({ netType: type });
  };

  setHasInternet = flag => {
    this.setState({ hasNet: flag });
  };

  setRemoteState = info => {
    this.setState({ remoteState: info });
  };

  setConnected = (state, pid) => {
    clearInterval(this.connectedInterval);
    this.setState({ connected: state, clientPid: pid });
    if (state && pid) {
      this.connectedInterval = setInterval(() => {
        if (this.state.connected) {
          if (this.state.clientPid > 0) {
            fetch("//rouster/remote/connected?pid=" + this.state.clientPid)
              .then(response => {
                if (!response.ok) {
                  throw Error("Error ping");
                }
                return response.json();
              })
              .then(json => {
                if ("result" in json && !json.result) {
                  this.setConnected(false, 0);
                }
              })
              .catch(err => {
                console.log(err);
              });
          } else {
            this.setConnected(false, 0);
          }
        }
      }, 2000);
    } else {
      this.showMain();
    }
  };

  // Change the path, so the Android app will get it as signal to start an activity,
  // passing the provided params in the query string. Then execute 'showMain()' in
  // order to prepare this app in a pause mode for when the user exits the activity.
  setAndroidActivity = (path, params={}, nextPath='/waiting') => {
    // Assign the history to the windows so the Android app can access and redirect
    // to wherever needed after completing its job.
    window.androidAppHistroy = this.wizard.history;

    let queryString = new URLSearchParams(params).toString();
    window.androidAppHistroy.push(`/${path}?${queryString}`);
    this.showMain();

    setTimeout(function(){ window.androidAppHistroy.push(nextPath); }, 100);
  }

  render() {
    return (
      <AppContext.Provider value={this}>
        <Container fluid style={{ padding: 0 }}>
          {this.state.connected && (
            <div className="page-overlay">
              <div className="col-lg-4 offset-lg-4 text-center">
                <h3>If you see this for more than a few seconds</h3>
                <p>
                  Try to press <em>Ctrl+Alt+Shift+q</em> than <em>Esc</em> to reconnect!
                </p>
                <p>If still the same, you need an additional verification.</p>
                <p>Please, contact your vendor for verification!</p>
              </div>
            </div>
          )}
          {this.state.user && <div className="sticky-top" onClick={ this.showMain }>Pause / Resume</div>}
          <StateBar
            data={{
              user: this.state.user,
              netType: this.state.netType,
              hasNet: this.state.hasNet,
              remoteState: this.state.remoteState,
              connected: this.state.connected || this.state.showSafeMode
            }}
          />
          <Router>
            {this.state.showMain && <Main user={this.state.user} />}
            {this.state.showSafeMode && <SafeMode user={this.state.user} />}
            <Switch>
              <Route
                path="/machine-not-ready"
                render={props => (
                  <div className="centered text-center">
                    <ErrorMessage
                      severity="danger"
                      message="Your machine is not yet ready. Please, try again later or contact Rouster team."
                    />
                  </div>
                )}
              />
              <Route
                render={({ history, match: { url } }) => (
                  <Wizard
                    ref={c => (this.wizard = c)}
                    history={history}
                    render={({ step, steps }) => (
                      <div className="centered text-center">
                        <div
                          className={
                            this.state.showMain || this.state.showSafeMode ? "icon-container bw" : "icon-container"
                          }
                        >
                          {/* <Icon
                            icon={antennaIcon}
                            active={step.id === "wizard/network"}
                            label="Network"
                            paused={this.state.showMain || this.state.showSafeMode}
                          /> */}
                          <Icon
                            icon={fingerprintIcon}
                            active={step.id === "wizard/auth"}
                            label="Authentication"
                            paused={this.state.showMain || this.state.showSafeMode}
                          />
                          <Icon
                            icon={upsIcon}
                            active={step.id === "wizard/remote"}
                            label="Power On"
                            paused={this.state.showMain || this.state.showSafeMode}
                          />
                          <Icon
                            icon={cloudIcon}
                            active={step.id === "wizard/connect"}
                            label="Connect"
                            paused={this.state.showMain || this.state.showSafeMode}
                          />
                        </div>
                        <label className="mt-4">
                          <div className={"rouster-label text-" + this.state.statusSeverity}>{this.state.status}</div>
                          <div className={"rouster-label text-" + this.state.statusSeverity}>
                            {this.state.secondaryStatus}
                          </div>
                        </label>
                        {!this.state.showMain && !this.state.showSafeMode && (
                          <div className="wizard-steps-container">
                            <Steps>
                              {/* <Step
                                id="wizard/network"
                                render={param => (
                                  <Network
                                    wizard={param}
                                    history={history}
                                    setNetworkType={this.setNetworkType}
                                    setHasInternet={this.setHasInternet}
                                    reportStatus={this.reportStatus}
                                    reportSecondaryStatus={this.reportSecondaryStatus}
                                    showMain={this.showMain}
                                  />
                                )}
                              /> */}
                              <Step
                                id="wizard/auth"
                                render={param => (
                                  <Authenticate
                                    wizard={param}
                                    history={history}
                                    user={this.state.user}
                                    setUser={this.setUser}
                                    reportStatus={this.reportStatus}
                                    reportSecondaryStatus={this.reportSecondaryStatus}
                                  />
                                )}
                              />
                              <Step
                                id="wizard/remote"
                                render={param => (
                                  <Remote
                                    wizard={param}
                                    history={history}
                                    setUser={this.setUser}
                                    user={this.state.user}
                                    setRemoteState={this.setRemoteState}
                                    reportStatus={this.reportStatus}
                                    reportSecondaryStatus={this.reportSecondaryStatus}
                                  />
                                )}
                              />
                              <Step
                                id="wizard/connect"
                                render={param => (
                                  <Connect
                                    wizard={param}
                                    history={history}
                                    user={this.state.user}
                                    reportStatus={this.reportStatus}
                                    reportSecondaryStatus={this.reportSecondaryStatus}
                                  />
                                )}
                              />
                            </Steps>
                          </div>
                        )}
                      </div>
                    )}
                  />
                )}
              />
            </Switch>
          </Router>
        </Container>
      </AppContext.Provider>
    );
  }
}

export default App;
