import React, { Component } from "react";
import { Trans } from "react-i18next";
import { ListGroup } from "react-bootstrap";
import { WifiSignalStrengthIcon } from "./WifiSignalStrengthIcon";
import WifiConnect from "./WifiConnect";

class WifiList extends Component {
  state = {
    selectedNetwork: null
  };

  setSelectedNetwork = net => {
    if (net.t_open && !net.connected) {
      this.props.onConnectToWifi(net, "");
    }
    this.setState({
      selectedNetwork: net.mac
    });
  };

  netState = (net, k) => {
    let currentState = [];
    if (net.enc === "on") {
      currentState.push(<Trans key={k + 700}>LBL_SECURED</Trans>);
    } else {
      currentState.push(<Trans key={k + 700}>LBL_OPEN</Trans>);
    }
    if (net.connected) {
      currentState.push(", ");
      currentState.push(<Trans key={k + 1700}>LBL_CONNECTED</Trans>);
    }

    return currentState;
  };

  render() {
    const current = this.props.list.filter(e => e.connected);
    const available = this.props.list.filter(e => !e.connected);

    return (
      <React.Fragment>
        <ListGroup as="ul" style={{ minWidth: 400 }} variant="flush">
          {current.length > 0 && (
            <ListGroup.Item as="li" key="id-0">
              <Trans>LBL_CURRENT_NETWORK</Trans>
            </ListGroup.Item>
          )}
          {current.map((net, k) => (
            <ListGroup.Item
              as="li"
              action
              key={k + 1000}
              title={`Signal strength ${net.strength}%`}
              onClick={e => this.setSelectedNetwork(net)}
            >
              <WifiSignalStrengthIcon strength={net.strength} />
              <label key={k + 2600}>
                {net.essid}
                <small key={k + 5800} style={{ display: "block", lineHeight: ".7rem" }}>
                  {this.netState(net, k)}
                </small>
              </label>
              {this.state.selectedNetwork === net.mac && (
                <WifiConnect
                  net={net}
                  onConnectToWifi={this.props.onConnectToWifi}
                  onDisconnectWifi={this.props.onDisconnectWifi}
                />
              )}
            </ListGroup.Item>
          ))}

          {available.length > 0 && (
            <ListGroup.Item as="li" key="id-1">
              <Trans>LBL_AVAILABLE_NETWORKS</Trans>
            </ListGroup.Item>
          )}

          {available.map((net, k) => (
            <ListGroup.Item
              as="li"
              action
              key={k + 2000}
              title={`Signal strength ${net.strength}%`}
              onClick={e => this.setSelectedNetwork(net)}
            >
              <WifiSignalStrengthIcon strength={net.strength} />
              <label key={k + 2600}>
                {net.essid}
                <small key={k + 5800} style={{ display: "block", lineHeight: ".7rem" }}>
                  {this.netState(net, k)}
                </small>
              </label>
              {this.state.selectedNetwork === net.mac && (
                <WifiConnect
                  net={net}
                  onConnectToWifi={this.props.onConnectToWifi}
                  onDisconnectWifi={this.props.onDisconnectWifi}
                />
              )}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </React.Fragment>
    );
  }
}

export default WifiList;
