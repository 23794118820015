import React, { Component } from "react";

class Icon extends Component {
  render() {
    let iconClass = "rouster-icon";
    if (this.props.active) {
      iconClass += " active";
    }

    if (this.props.paused) {
      iconClass += " pause";
    }
    return (
      <>
        <div className={iconClass}>
          <i
            style={{
              backgroundImage: `url(${this.props.icon})`
            }}
          />
          <small className="label">{this.props.label}</small>
        </div>
      </>
    );
  }
}

export default Icon;
