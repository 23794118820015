import React, { Component } from "react";
import { Trans } from "react-i18next";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

import logo from "../assets/rouster-logo.svg";
// import iconInternet from "../assets/internet.svg";
import iconShutdown from "../assets/shutdown.svg";
import iconRestart from "../assets/restart.svg";
// import iconSleep from "../assets/sleep.svg";
import iconSafemode from "../assets/safe-mode.svg";
import iconShield from "../assets/shield.svg";
import iconUser from "../assets/user.svg";

class Navigation extends Component {
  render() {
    return (
      <Navbar expand="lg" variant="dark" className="w-100" defaultExpanded>
        <Navbar.Brand as={Link} to="/">
          <img src={logo} height="15" alt="Logo" align="middle" className="m-1" />
        </Navbar.Brand>
        {/* <Navbar.Toggle aria-controls="main-navbar-nav" /> */}
        <Navbar.Collapse id="main-navbar-nav">
          <Nav>
            {/* <Nav.Item>
              <Nav.Link
                as={Link}
                to="/network"
                className={this.props.location.pathname.startsWith("/network") ? "active" : ""}
              >
                <i
                  style={{
                    backgroundImage: `url(${iconInternet})`
                  }}
                />
                <Trans>NAV_NETWORK</Trans>
              </Nav.Link>
            </Nav.Item> */}

            <Nav.Item>
              <Nav.Link
                disabled={!this.props.user}
                as={Link}
                to="/safe-mode"
                className={this.props.location.pathname.startsWith("/safe-mode") ? "active" : ""}
              >
                <i
                  style={{
                    backgroundImage: `url(${iconSafemode})`
                  }}
                />
                <Trans>NAV_SAFEMODE</Trans>
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/restart"
                className={this.props.location.pathname.startsWith("/restart") ? "active" : ""}
              >
                <i
                  style={{
                    backgroundImage: `url(${iconRestart})`
                  }}
                />
                <Trans>NAV_RESTART</Trans>
              </Nav.Link>
            </Nav.Item>

            {/* <Nav.Item>
              <Nav.Link
                as={Link}
                to="/sleep"
                className={this.props.location.pathname.startsWith("/sleep") ? "active" : ""}
              >
                <i
                  style={{
                    backgroundImage: `url(${iconSleep})`
                  }}
                />
                <Trans>NAV_SLEEP</Trans>
              </Nav.Link>
            </Nav.Item> */}

            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/signout"
                className={this.props.location.pathname.startsWith("/signout") ? "active" : ""}
              >
                <i
                  style={{
                    backgroundImage: `url(${iconShield})`
                  }}
                />
                <Trans>NAV_SIGNOUT</Trans>
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/shutdown"
                className={this.props.location.pathname.startsWith("/shutdown") ? "active" : ""}
              >
                <i
                  style={{
                    backgroundImage: `url(${iconShutdown})`
                  }}
                />
                <Trans>NAV_SHUTDOWN</Trans>
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/settings"
                className={this.props.location.pathname.startsWith("/settings") ? "active" : ""}
              >
                <i
                  style={{
                    backgroundImage: `url(${iconUser})`
                  }}
                />
                <Trans>NAV_SETTINGS</Trans>
              </Nav.Link>
            </Nav.Item>

            {/* <Nav.Item>
              <Nav.Link
                as={Link}
                to="/deleteconnection"
                className={this.props.location.pathname.startsWith("/deleteconnection") ? "active" : ""}
              >
                <i
                  style={{
                    backgroundImage: `url(${iconShutdown})`
                  }}
                />
                <Trans>NAV_DELETE_CONNECTION</Trans>
              </Nav.Link>
            </Nav.Item> */}

          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default Navigation;
