import React, { Component } from "react";
import { Trans } from "react-i18next";
import { Row, Col, Button } from "react-bootstrap";
import { Spinner } from "../Spinner";
import { ErrorMessage } from "../ErrorMessage";
import FormatBytes from "../FormatBytes";

import leftArrowIcon from "../../assets/left-arrow.svg";

class SpeedTest extends Component {
  state = {
    loading: false,
    info: null,

    error: null
  };

  controller = new AbortController();

  runSpeedTest() {
    this.setState({ loading: true });
    this.controller = new AbortController();

    fetch("//rouster/network/speed", { signal: this.controller.signal })
      .then(response => {
        if (!response.ok) {
          throw Error("Error while running the speed test");
        }
        return response.json();
      })
      .then(json => {
        localStorage.setItem("speedtest", JSON.stringify(json));
        this.setState({ loading: false, info: json });
      })
      .catch(err => {
        this.setState({ loading: false, error: err });
      });
  }

  /**
   * Run the speed test
   */
  componentDidMount() {
    if (localStorage.getItem("speedtest") != null) {
      this.setState({ info: JSON.parse(localStorage.getItem("speedtest")) });
    } else {
      this.runSpeedTest();
    }
  }

  componentWillUnmount() {
    this.controller.abort();
  }

  render() {
    if (this.state.error != null) {
      return <ErrorMessage message={this.state.error.toString()} />;
    }
    if (this.state.loading) {
      return <Spinner />;
    }

    if (!this.state.info) {
      return null;
    }

    return (
      <div>
        <h4 className="m-3">
          <Trans>TITLE_SPEED_TEST</Trans>
          <div className="float-right mr-2">
            <i
              onClick={() => this.props.history.push("/network")}
              style={{
                verticalAlign: "bottom",
                width: 35,
                height: 35,
                backgroundImage: `url(${leftArrowIcon})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                display: "inline-block"
              }}
            />
          </div>
        </h4>

        <div className="m-4">
          <Row>
            <Col xs={3}>
              <Trans>LBL_PING</Trans>:
            </Col>{" "}
            <Col>{this.state.info["ping"]} ms</Col>
          </Row>
          <Row>
            <Col xs={3}>
              <Trans>LBL_DOWNLOAD_SPEED</Trans>:
            </Col>
            <Col>{Math.round(this.state.info["download"] / 1000 / 1000, 2)} Mbs</Col>
          </Row>
          <Row>
            <Col xs={3}>
              <Trans>LBL_UPLOAD_SPEED</Trans>:
            </Col>
            <Col>{Math.round(this.state.info["upload"] / 1000 / 1000, 2)} Mbs</Col>
          </Row>
          <Row>
            <Col xs={3}>
              <Trans>LBL_SERVER</Trans>:
            </Col>{" "}
            <Col>
              {this.state.info["server"]["sponsor"]}/{this.state.info["server"]["country"]}/
              {this.state.info["server"]["name"]}
            </Col>
          </Row>
          <Row>
            <Col xs={3}>
              <Trans>LBL_BYTES_SENT</Trans>:
            </Col>
            <Col>{FormatBytes(this.state.info["bytes_sent"], 2)}</Col>
          </Row>
          <Row>
            <Col xs={3}>
              <Trans>LBL_BYTES_RECEIVED</Trans>:
            </Col>
            <Col>{FormatBytes(this.state.info["bytes_received"], 2)}</Col>
          </Row>
          <div className="mt-4">
            <Button type="button" variant="dark" size="sm" onClick={() => this.runSpeedTest()}>
              <Trans>BTN_RUN_AGAIN</Trans>
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default SpeedTest;
