import React, { Component } from "react";
import { Trans } from "react-i18next";
import { Button, ListGroup } from "react-bootstrap";
import { Spinner } from "../Spinner";
import WifiList from "./WifiList";
import { ErrorMessage } from "../ErrorMessage";

import leftArrowIcon from "../../assets/left-arrow.svg";
//import refreshIcon from "../../assets/refresh.svg";

class WifiManager extends Component {
  state = {
    loading: false,
    wifiList: [],

    error: null
  };

  controller = new AbortController();

  loadWiFiList = () => {
    this.setState({ loading: true });
    this.controller = new AbortController();

    fetch("//rouster/network/wifi/list", { signal: this.controller.signal })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        this.setState({ loading: false, wifiList: this.state.wifiList });
      })
      .then(json => {
        if (Array.isArray(json) && json.length > 0) {
          this.setState({ loading: false, wifiList: json });
        }
      })
      .catch(err => {});
  };

  /**
   * Obtain list of available WiFi network
   */
  componentDidMount() {
    this.loadWiFiList();
  }

  componentWillUnmount() {
    this.controller.abort();
  }

  /**
   * Handle connect to WiFi network
   *
   * @param {*} wifi
   * @param {*} secret
   */
  handleConnectToWifi = (net, secret) => {
    this.setState({ loading: true });
    var formData = new FormData();

    formData.append("ssid", net.essid);
    formData.append("pwd", secret);
    let type;
    if (net.t_open) {
      type = "open";
    } else if (net.t_wpa2) {
      type = "wpa2";
    } else if (net.t_wpa) {
      type = "wpa";
    } else if (net.t_wep) {
      type = "wep";
    }
    formData.append("type", type);

    this.controller = new AbortController();

    fetch("//rouster/network/wifi/setup", {
      method: "POST",
      body: formData,
      signal: this.controller.signal
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        this.setState({ loading: false, error: "An error occurred during WiFi connect." });
      })
      .then(json => {
        if (null !== json) {
          document.dispatchEvent(new KeyboardEvent("keydown", { keyCode: 27 }));
        }
      })
      .catch(err => {});
  };

  /**
   * Handle connec to WiFi network
   *
   * @param {*} wifi
   * @param {*} secret
   */
  handleDisconnectToWifi = () => {
    this.setState({ loading: true });
    this.controller = new AbortController();

    fetch("//rouster/network/wifi/disconnect", {
      method: "POST",
      signal: this.controller.signal
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        this.setState({ loading: false, error: "An error occurred during WiFi disconnect." });
      })
      .then(json => {
        if (null !== json) {
          this.loadWiFiList();
        }
      })
      .catch(err => {});
  };

  render() {
    if (this.state.error != null) {
      return <ErrorMessage message={this.state.error.toString()} />;
    }
    if (this.state.loading) {
      return <Spinner />;
    }

    if (this.state.wifiList.length === 0)
      return (
        <ListGroup variant="flush">
          <ListGroup.Item>
            <Trans>LBL_NO_NETWORKS_AVAILABLE</Trans>
          </ListGroup.Item>
        </ListGroup>
      );

    return (
      <div>
        <h4 className="m-3">
          <label>
            <Trans>TITLE_WIFI_NETWORKS</Trans>
          </label>

          <span className="refreshBtn ml-3">
            <Button type="button" variant="dark" size="sm" onClick={() => this.loadWiFiList()}>
              <Trans>BTN_REFRESH</Trans>
            </Button>
          </span>
          <div className="float-right mr-2">
            <i
              onClick={() => this.props.history.push("/network")}
              style={{
                verticalAlign: "bottom",
                width: 35,
                height: 35,
                backgroundImage: `url(${leftArrowIcon})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                display: "inline-block"
              }}
            />
          </div>
        </h4>
        <WifiList
          list={this.state.wifiList}
          onConnectToWifi={this.handleConnectToWifi}
          onDisconnectWifi={this.handleDisconnectToWifi}
        />
      </div>
    );
  }
}

export default WifiManager;
