import React, { Component } from "react";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import { SignIn, SignUp, ConfirmSignIn, VerifyContact, Loading, withAuthenticator } from "aws-amplify-react";
import config from "./config.js";

import * as query from "../graphql/queries";

Amplify.configure(config.amplify);

class Authenticate extends Component {
  state = {
    user: null
  };

  _isMounted = false;

  GetUserInfo = () => {
    this.props.reportStatus("Client authentication in progress");

    API.graphql(graphqlOperation(query.GetUserInfo))
      .then(({ data }) => {
        if (this._isMounted) {
          if (data && "GetUserInfo" in data) {
            this.setState({ user: data.GetUserInfo });
          } else {
            throw new Error("Cannot load user data.");
          }
        }
      })
      .then(() => {
        if (this._isMounted) {
          if ("Id" in this.state.user) {
            this.props.reportStatus("Device authentication successful");
            this.props.setUser(this.state.user);
            this.props.wizard.push("wizard/remote");
          } else {
            this.props.reportStatus("Device not authenticated. Please, wait...");
            this.props.wizard.push("wizard/network");
          }
        }
      })
      .catch(error => {
        if (this._isMounted) this.props.wizard.push("wizard/network");
      });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;

    if (!this.props.user || !("Id" in this.props.user)) {
      // We need to get the user data from the API
      this.GetUserInfo();
    } else {
      // User data is already here. Skip this step
      this.props.wizard.push("wizard/remote");
    }
  }

  render = () => null;
}

export default withAuthenticator(
  Authenticate,
  {
    usernameAttributes: "email",
    signUpConfig: {
      hiddenDefaults: ["phone_number"]
    }
  },
  [<SignIn />, <SignUp />, <ConfirmSignIn />, <VerifyContact />, <Loading />]
);
