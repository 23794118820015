export const CloudComputerStart = `mutation CloudComputerStart {
  CloudComputerStart
}`;

export const CloudComputerStop = `mutation CloudComputerStop {
  CloudComputerStop
}`;

export const CloudComputerRestart = `mutation CloudComputerRestart {
  CloudComputerRestart
}`;

export const CloudComputerAccessKey = `mutation CloudComputerAccessKey{ 
  CloudComputerAccessKey
}`;
