import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import SpeedTest from "./SpeedTest";
import WifiManager from "./WifiManager";
import WiredManager from "./WiredManager";
import NetworkManager from "./NetworkManager";

class NetworkLayout extends Component {
  render() {
    return (
      <Switch>
        <Route path="/network/wifi-manager" render={props => <WifiManager {...props} />} />
        <Route path="/network/wired-connection" component={WiredManager} />
        <Route path="/network/speed-test" component={SpeedTest} />
        <Route component={NetworkManager} />
      </Switch>
    );
  }
}

export default NetworkLayout;
