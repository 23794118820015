import React, { Component } from "react";

import userIcon from "../assets/user.svg";
import wiredIcon from "../assets/wired.svg";
import wifiIcon from "../assets/wireless2.svg";
import wwwIcon from "../assets/www.svg";
import wwwCloudPower from "../assets/cloud-power.svg";
import wwwChain from "../assets/chain.svg";

class StateBar extends Component {
  render() {
    return (
      <div className="state-bar">
        {this.props.data.netType === "wlan" && (
          <i
            className="state-icon"
            style={{
              backgroundImage: `url(${wifiIcon})`
            }}
          />
        )}

        {this.props.data.netType === "eth" && (
          <i
            className="state-icon"
            style={{
              backgroundImage: `url(${wiredIcon})`
            }}
          />
        )}

        {this.props.data.hasNet && (
          <i
            className="state-icon"
            style={{
              backgroundImage: `url(${wwwIcon})`
            }}
          />
        )}

        {this.props.data.user && (
          <i
            className="state-icon"
            style={{
              backgroundImage: `url(${userIcon})`
            }}
          />
        )}

        {this.props.data.remoteState === "ready" && (
          <i
            className="state-icon"
            style={{
              backgroundImage: `url(${wwwCloudPower})`
            }}
          />
        )}

        {this.props.data.connected && (
          <i
            className="state-icon"
            style={{
              backgroundImage: `url(${wwwChain})`
            }}
          />
        )}
      </div>
    );
  }
}

export default StateBar;
