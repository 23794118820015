import React, { Component } from "react";
import { Trans } from "react-i18next";
import { Button, Row, Col } from "react-bootstrap";
import { Spinner } from "../Spinner";
import { ErrorMessage } from "../ErrorMessage";

import leftArrowIcon from "../../assets/left-arrow.svg";

class WiredManager extends Component {
  state = {
    loading: false,
    error: null,
    wired_data: {}
  };

  controller = new AbortController();

  componentDidMount() {
    this.controller = new AbortController();

    fetch("//rouster/network/info", { signal: this.controller.signal })
      .then(response => {
        if (!response.ok) {
          throw Error("Error while getting network data: " + response.statusText);
        }
        return response.json();
      })
      .then(json => {
        if (Array.isArray(json) && json.length > 0) {
          json
            .filter(e => e.state === "up" && "ip_address" in e && e.interface === "eth0")
            .map(i => {
              this.setState({ loading: false, wired_data: i });
              return i;
            });
        }
      })
      .catch(err => {
        this.setState({ error: err });
      });
  }

  componentWillUnmount() {
    this.controller.abort();
  }

  render() {
    if (this.state.error != null) {
      return <ErrorMessage message={this.state.error.toString()} />;
    }
    if (this.state.loading) {
      return <Spinner />;
    }

    return (
      <div>
        <h4 className="m-3">
          <Trans>TITLE_WIRED_INTERFACE</Trans>
          <div className="float-right mr-2">
            <i
              onClick={() => this.props.history.goBack()}
              style={{
                verticalAlign: "bottom",
                width: 35,
                height: 35,
                backgroundImage: `url(${leftArrowIcon})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                display: "inline-block"
              }}
            />
          </div>
        </h4>

        <div className="m-4">
          <Row>
            <Col xs={2}>
              <Trans>LBL_IP_ADDRESS</Trans>:
            </Col>{" "}
            <Col>{this.state.wired_data["ip_address"]}</Col>
          </Row>
          <Row>
            <Col xs={2}>
              <Trans>LBL_NETMASK</Trans>:
            </Col>{" "}
            <Col>{this.state.wired_data["netmask"]}</Col>
          </Row>

          <Row>
            <Col xs={2}>
              <Trans>LBL_NET_GATEWAY</Trans>:
            </Col>{" "}
            <Col>{this.state.wired_data["broadcast"]}</Col>
          </Row>
          <Row>
            <Col xs={2}>
              <Trans>LBL_MAC_ADDRESS</Trans>:
            </Col>
            <Col>{this.state.wired_data["ether"]}</Col>
          </Row>
          <div className="mt-4">
            <Button type="button" variant="dark" size="sm" onClick={() => this.props.history.goBack()}>
              <Trans>BTN_OK</Trans>
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default WiredManager;
