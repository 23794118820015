import React, { Component } from "react";
import { Trans } from "react-i18next";
import { Form, Col, Row, Button } from "react-bootstrap";
import { Spinner } from "./Spinner";
import { ErrorMessage } from "./ErrorMessage";
import Amplify, { API, graphqlOperation, Auth } from "aws-amplify";
import config from "./config.js";

import * as mutation from "../graphql/mutations";
import { AppContext } from "../app-context";

Amplify.configure(config.amplify);

class DevicePower extends Component {
  state = {
    loading: false,
    error: null
  };

  sleepAction = () => {
    this.setState({ loading: true });
    fetch("//rouster/power/turn-off", {
      method: "POST"
    })
      .then(response => {
        if (!response.ok) {
          throw Error("Error while putting to sleep.");
        }
        return response.json();
      })
      .then(() => {
        this.props.history.push("/");
      })
      .catch(err => {
        this.setState({ error: err, loading: false });
      });
  };

  restartAction = () => {
    this.setState({ loading: true });
    API.graphql(graphqlOperation(mutation.CloudComputerRestart)).then(() => {
      setTimeout(() => {this.props.history.push("/");}, 15000);
    });
  };

  shutdownAction = () => {
    this.setState({ loading: true });
    API.graphql(graphqlOperation(mutation.CloudComputerStop)).then(() => {
      this.props.history.push("/doShutDown");
    });
  };

  deleteConnectionAction = () => {
    API.graphql(graphqlOperation(mutation.CloudComputerStop)).then(() => {
      this.context.setAndroidActivity('doDeleteConnection');  
    });
  };

  signoutAction = () => {
    API.graphql(graphqlOperation(mutation.CloudComputerStop)).then(() => {
      Auth.signOut().then(() => {
        this.context.setAndroidActivity('doDeleteConnection');  
        this.context.setState({ user: null, loading: false });
        this.context.hideMain();
      });
    });
  };

  cancelAction = () => {
    this.props.history.push("/on-hold");
  };

  render() {
    if (this.state.error != null) {
      return <ErrorMessage message={this.state.error.toString()} />;
    }
    if (this.state.loading || this.props.action === "waiting") {
      return <Spinner />;
    } else {
      if (this.props.action === "sleep") {
        return (
          <div style={{ backgroundColor: "#fff", padding: "1rem" }}>
            <Form className="m-3">
              <Form.Group>
                <Form.Label>
                  <h4>
                    <Trans>MSG_CONFIRM_SLEEP_DEVICE</Trans>
                  </h4>
                  <p>
                    <Trans>MSG_CONFIRM_SLEEP_INFO</Trans>
                  </p>
                </Form.Label>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm>
                  <Button variant="danger" type="button" className="m-2" onClick={this.sleepAction}>
                    <Trans>BTN_SLEEP</Trans>
                  </Button>
                  <Button variant="secondary" type="button" className="m-2" onClick={this.cancelAction}>
                    <Trans>BTN_CANCEL</Trans>
                  </Button>
                </Col>
              </Form.Group>
            </Form>
          </div>
        );
      } else if (this.props.action === "restart") {
        return (
          <div style={{ backgroundColor: "#fff", padding: "1rem" }}>
            <Form className="m-3">
              <Form.Group>
                <Form.Label>
                  <h4>
                    <Trans>MSG_CONFIRM_RESTART_DEVICE</Trans>
                  </h4>
                </Form.Label>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm>
                  <Button variant="danger" type="button" className="m-2" onClick={this.restartAction}>
                    <Trans>BTN_RESTART</Trans>
                  </Button>
                  <Button variant="secondary" type="button" className="m-2" onClick={this.cancelAction}>
                    <Trans>BTN_CANCEL</Trans>
                  </Button>
                </Col>
              </Form.Group>
            </Form>
          </div>
        );
      } else if (this.props.action === "signout") {
        return(
          <div style={{ backgroundColor: "#fff", padding: "1rem" }}>
            <Form className="m-3">
              <Form.Group>
                <Form.Label>
                  <h4>
                    <Trans>MSG_CONFIRM_SIGNOUT_DEVICE</Trans>
                  </h4>
                </Form.Label>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm>
                  <Button variant="danger" type="button" className="m-2" onClick={this.signoutAction}>
                    <Trans>BTN_SIGNOUT</Trans>
                  </Button>
                  <Button variant="secondary" type="button" className="m-2" onClick={this.cancelAction}>
                    <Trans>BTN_CANCEL</Trans>
                  </Button>
                </Col>
              </Form.Group>
            </Form>
          </div>
        );
      } else if (this.props.action === "deleteconnection") {
        return (
          <div style={{ backgroundColor: "#fff", padding: "1rem" }}>
            <Form className="m-3">
              <Form.Group>
                <Form.Label>
                  <h4>
                    <Trans>MSG_CONFIRM_DELETE_CONNECTION</Trans>
                  </h4>
                </Form.Label>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm>
                  <Button variant="danger" type="button" className="m-2" onClick={this.deleteConnectionAction}>
                    <Trans>BTN_DELETE_CONNECTION</Trans>
                  </Button>
                  <Button variant="secondary" type="button" className="m-2" onClick={this.cancelAction}>
                    <Trans>BTN_CANCEL</Trans>
                  </Button>
                </Col>
              </Form.Group>
            </Form>
          </div>
        );
      } else if (this.props.action === "shutdown") {
        return (
          <div style={{ backgroundColor: "#fff", padding: "1rem" }}>
            <Form className="m-3">
              <Form.Group>
                <Form.Label>
                  <h4>
                    <Trans>MSG_CONFIRM_SHUTDOWN_DEVICE</Trans>
                  </h4>
                </Form.Label>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm>
                  <Button variant="danger" type="button" className="m-2" onClick={this.shutdownAction}>
                    <Trans>BTN_SHUTDOWN</Trans>
                  </Button>
                  <Button variant="secondary" type="button" className="m-2" onClick={this.cancelAction}>
                    <Trans>BTN_CANCEL</Trans>
                  </Button>
                </Col>
              </Form.Group>
            </Form>
          </div>
        );
      } else {
        return (
          <div style={{ backgroundColor: "#fff", padding: "1rem" }}>
            <p>
              <Trans>MSG_SOMETHING_WENT_WRONG</Trans>
            </p>
          </div>
        )
      }
    }
  }
}

DevicePower.contextType = AppContext;

export default DevicePower;
