import React from "react";
import { Trans } from "react-i18next";

export const Spinner = () => (
    <div style={{ backgroundColor: "#fff", padding: "1rem" }}>
      <p>
        <Trans>MSG_SPINNER</Trans>
      </p>
      <div className="spinner" />
    </div>
);
