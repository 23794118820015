import { Component } from "react";
import { AppContext } from "../app-context";

class Connect extends Component {
  controller = new AbortController();

  timerSec = 3;
  timer = null;
  retryHandler = null;
  retryCount = 5;

  countDown = fnc => {
    if (this.timerSec < 0) {
      clearInterval(this.timer);
      fnc();
    } else if (null === this.timer) {
      this.timer = setInterval(() => {
        this.props.reportStatus(`Connecting in ${this.timerSec} sec.`);
        this.timerSec--;
        this.countDown(fnc);
      }, 1000);
    }
  };

  doConnect = () => {
    this.props.reportStatus("Connecting...");
    this.controller = new AbortController();
    
    this.context.setAndroidActivity('doConnect', {
      "computer_ip_address": this.props.user.computer.publicIpAddress
    });

    // fetch("//rouster/remote/connect", { signal: this.controller.signal })
    //   .then(response => {
    //     if (response.ok) {
    //       return response.json();
    //     }
    //     this.props.wizard.push("wizard/network");
    //   })
    //   .then(json => {
    //     if ("pid" in json && json.pid > 0) {
    //       this.props.reportStatus("Connected");
    //       this.context.setConnected(true, json.pid);
    //     } else {
    //       this.retryHandler = setTimeout(() => {
    //         clearInterval(this.timer);
    //         this.timer = null;
    //         this.timerSec = 5;
    //         if (--this.retryCount <= 0) {
    //           this.props.wizard.push("wizard/network");
    //         } else {
    //           this.countDown(() => {
    //             this.doConnect();
    //           });
    //         }
    //       }, 2000);
    //     }
    //   })
    //   .catch(err => {});
  };

  componentWillUnmount() {
    this.controller.abort();
    clearInterval(this.timer);
    clearTimeout(this.retryHandler);
  }

  componentDidMount() {
    this.countDown(() => {
      this.doConnect();
    });
  }

  render() {
    return null;
  }
}

Connect.contextType = AppContext;

export default Connect;
