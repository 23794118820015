import React, { Component } from "react";
//import { Trans } from "react-i18next";
import { withRouter } from "react-router";
import { Modal } from "react-bootstrap";
import { Route, Switch, Redirect } from "react-router-dom";
import { AppContext } from "../app-context";

import Navigation from "./Navigation";

import NetworkLayout from "./network/NetworkLayout";
import DevicePower from "./DevicePower";

class Main extends Component {
  state = {
    show: true
  };

  render() {
    const NavigationWithRouter = withRouter(Navigation);

    return (
      <Modal
        show={this.state.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={() => {
          this.setState({ show: false });
          this.context.hideMain();
        }}
        className="modal-main"
      >
        <Modal.Header>
          <NavigationWithRouter user={this.props.user} />
        </Modal.Header>

        <Modal.Body>
          {this.state.show && (
            <Switch>
              <Route path="/network" component={NetworkLayout} />
              <Route
                path="/safe-mode"
                component={() => {
                  this.setState({ show: false });
                  this.context.hideMain();
                  this.context.showSafeMode();
                  return null;
                }}
              />
              <Route
                path="/settings"
                component={() => {
                  this.context.setAndroidActivity('doSettings', {}, '/on-hold');
                  return null;
                }}
              />
              <Route path="/sleep" render={props => <DevicePower {...props} action="sleep" />} />
              <Route path="/restart" render={props => <DevicePower {...props} action="restart" />} />
              <Route path="/signout" render={props => <DevicePower {...props} action="signout" />} />
              <Route path="/shutdown" render={props => <DevicePower {...props} action="shutdown" />} />
              <Route path="/doShutDown" render={props => <DevicePower {...props} action="waiting" />} />
              <Route path="/deleteconnection" render={props => <DevicePower {...props} action="deleteconnection" />} />
              <Route path="/oops" render={props => <DevicePower {...props} action="oops" />} />
              <Route path="/waiting" render={props => <DevicePower {...props} action="waiting" />} />
              <Redirect to="/on-hold" />
            </Switch>
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

Main.contextType = AppContext;

export default Main;
