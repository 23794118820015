import React from "react";
import wifiXS from "../../assets/wifi-0.svg";
import wifiS from "../../assets/wifi-25.svg";
import wifiM from "../../assets/wifi-50.svg";
import wifiL from "../../assets/wifi-75.svg";
import wifiXL from "../../assets/wifi-100.svg";

export const WifiSignalStrengthIcon = ({
  /** int from 0 to 100**/ strength
}) => {
  const icons = [wifiXS, wifiS, wifiM, wifiL, wifiXL],
    limitValues = [0, 25, 50, 75, 100].map(i => Math.abs(i - strength)),
    index = limitValues.indexOf(Math.min(...limitValues));
  return (
    <i
      className="mr-2"
      style={{
        width: 30,
        height: 30,
        backgroundImage: `url(${icons[index]})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        display: "inline-block"
      }}
    />
  );
};
